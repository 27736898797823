import React from "react"
import {
  AboutUsContactUsSection,
  AboutUsHeroSection,
  AboutUsMeetTheAdvisorySection,
  AboutUsMeetTheTeamSection, AboutUsOurPartnersSection,
  AboutUsSection,
  AboutUsWhoWeAreSection
} from "@/layout";

const AboutUsPage: React.FC = () => {
    return (
        <div className={'container-aboutus'}>
            <AboutUsHeroSection/>
            <AboutUsSection/>
            {/* <AboutUsWhoWeAreSection/> */}
            <AboutUsMeetTheTeamSection/>
            <AboutUsMeetTheAdvisorySection/>
            <AboutUsOurPartnersSection/>
            <AboutUsContactUsSection/>
        </div>
    )
}

export default AboutUsPage
